<template>
  <div>
    <v-row>
      <v-col cols="7">
        <h3 class="mb-4">Добавить маршрут</h3>
      </v-col>

      <v-col cols="5">
        <v-text-field
          v-model="searchText"
          label="Поиск маршрутов"
          placeholder="Введите номер или шифр маршрута"
          class="pt-0 mt-0"
          clearable
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="filteredRoutes"
      :loading="loading"
      no-data-text="Нет записей"
      height="400"
      loading-text="Загрузка списка маршрутов"
      class="mb-4"
      fixed-header
      show-select
      disable-pagination
      hide-default-footer
    ></v-data-table>

    <v-row dense>
      <v-col>
        <v-btn
          outlined
          color="red"
          @click="cancelAdd"
        >
          Отмена
        </v-btn>
      </v-col>

      <v-col class="text-right">
        <v-btn
          color="primary"
          @click="addRoutes"
          :disabled="selected.length === 0"
        >
          Добавить выбранные
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'AllRoutes',
  props: {
    items: {
      type: Array,
      required: true,
    },
    routes: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      selected: [],
      searchText: '',
      headers: [
        {
          text: 'Шифр маршрута',
          value: 'code',
        },
        {
          text: '№ маршрута',
          value: 'routeCode',
        },
        {
          text: 'Наименование маршрута',
          value: 'routeName',
        },
        {
          text: 'Номер версии',
          value: 'versionNumber',
        },
        {
          text: 'Статус версии маршрута',
          value: 'versionStatus',
        },
      ],
    };
  },
  computed: {
    filteredRoutes() {
      const re = new RegExp(this.searchText, 'i');

      return this.items.filter(route => {
        let index = this.routes.findIndex(item => {
          return item.id === route.id;
        });

        if (this.searchText && !(re.test(route.routeCode) || re.test(route.routeNumber))) {
          return false;
        }
        return index === -1;
      });
    },
  },
  methods: {
    addRoutes() {
      this.$emit('onAdd', this.selected);

      this.selected = [];
    },
    cancelAdd() {
      this.selected = [];

      this.$emit('onCancel');
    },
  },
};
</script>

